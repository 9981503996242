import React from 'react'
import { Link, graphql } from 'gatsby'
import styled from 'styled-components'
import useSiteMetadata from '../hooks/use-site-config'
import { colors, media } from '../tokens'
import useSiteImages from '../hooks/use-site-images'
import { Navbar, Nav, NavDropdown, Container, Row, Col, Form, CardDeck, Card, Button, Accordion } from 'react-bootstrap'
import { Bull, ReadingTime } from './Commons'

const CustomAccordion = styled(Accordion)`
    .btn-link {
        font-weight: 400;
        color: ${colors.dypBlue};
        text-decoration: none;
    }

    .btn-link :hover {
        color: ${colors.dypPurpleLight};
    }
`

const AccordionCard = props =>{
    const {index, title, content} = props;
    //console.log(title)
    return (
        <Card>
            <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey={index}>
                {title}
            </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={index}>
            <Card.Body>{content}</Card.Body>
            </Accordion.Collapse>
        </Card>
    )
}

const FAQ = props => {
    const {faqs} = props;
    //console.log(faqs)
  return (
    <CustomAccordion defaultActiveKey="-1">
        {faqs.map((faq, i) => (
            <AccordionCard index={i} title={faq.title} content={faq.content}></AccordionCard>
        ))}
    </CustomAccordion>
  )
}

export default FAQ

