import React from "react"
import Link from "../components/Link"
import styled from 'styled-components'
import {LayoutPage} from "../components/Layouts"
import { colors, media } from '../tokens'
import { Form, Button, Container, Row, Col, Card, Accordion } from "react-bootstrap"
import useSiteMetadata from "../hooks/use-site-config"
import FAQ from "../components/FAQ"

const Section = styled.section`
    padding-top: 25px;
    margin-bottom: 25px;
`

const CustomButton = styled(Button)`
    background-color: ${colors.dypBlue};
    border-color: ${colors.dypBlue};
    margin: 5px;

    :hover {
        background-color: ${colors.dypPurple};
        border-color: ${colors.dypPurple};
    }
`

const CustomLink = styled(Link)`
    background-color: ${colors.dypBlue};
    border-color: ${colors.dypBlue};
    margin: 5px;
    color: white;

    :hover {
        background-color: ${colors.dypPurple};
        border-color: ${colors.dypPurple};
        color:white;
        text-decoration: none;
    }

    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`

export default () => {
    const {supportEmail, forumUrl, socialDiscord} = useSiteMetadata()

    return(
    <LayoutPage 
    location="/Support/"
    title="SUPPORT"
    heroImg=""
    description="Contact us through our forum and discord"
    >
        <Section>
            <p>
                We are dedicated to help you with any of the assets you purchase from us.
                If you have any questions please take a look at the FAQ, the documentation and the Forum. 
                Whenever possible use the Forum to ask questions as it will help others that may also have the same question.
                If you require assistance in which you do not want to share sensible information with the public please contact us by email  
                at <a href={"mailto:"+supportEmail}>{supportEmail}</a>
            </p>
        </Section>

        <Section>
            <h2>Join the forum and our Discord!</h2>
            <p>
                Join the forum and Discord where our awesome community of gamers and developers discuss about different topics.
                If you have any questions about the assets you bought people from our community will help you out in the forums.
            </p>
            <CustomLink to={forumUrl}>DYPSLOOM FORUM</CustomLink>
            <CustomLink to={socialDiscord}>DYPSLOOM DISCORD</CustomLink>
        </Section>

        <Section id="Documentation">
            <h2>Asset Documentation</h2>
            <p>
                Some of our assets have extensive documentation to allow you to take full advantage of them.
                The documentation is always up to date with the last version of each asset.
                You may find the documentation for each asset on their page.
            </p>
            <CustomLink to="/Assets">MORE ASSETS</CustomLink>
            
        </Section>
           
        <Section>
            <h2>FAQ</h2>
            <FAQ faqs={[
                {
                    title: "Where can I purchase these assets and games?",
                    content: "The assets are sold on the Unity asset store. The games will be sold on Steam and/or Itch.io"
                },
                {
                    title: "Do you offer consulting work?",
                    content: "We do! Most of the Dypsloom group members work independently and each have a different consulting rate. Send us an email at "+supportEmail+" and we will give you an offer."
                },
                {
                    title: "Can I use the assets I purchased in my game?",
                    content: "Yes of course. You can use the assets in your game. What you are not allowed to do is sell or give away the raw assets, read the Terms and Conditions for more information."+
                    "\nIt is not required but we would be really thankful if you credited us when publishing your game :)"
                }]}>

            </FAQ>
        </Section>
    </LayoutPage>
)}